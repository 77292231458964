import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./travelspiritbackoffice.scss";
import wecloudIt from '../assets/images/portfolio-WeCloudIt.webp';
import travelIntelligence from '../assets/images/travel-Intelligence.webp';
import kofferLabel from '../assets/images/labels/backoffice-label.png';
import addonVideo from '../assets/videos/mediaspirit-academy.mp4';
import { Link } from "gatsby";

export default ({ data }) => {
    return (
        <Layout>
            <SEO title="Travelspirit Backoffice" urlParams="travelspiritbackoffice" />
            <div className="travel-page-full back-office-section-first">
                <div className="travel-page-container">
                    <div className="back-office-content">
                        <h6>De TravelSpirit </h6>
                        <div className="back-office-wrap">
                            <h1>BACK<br />
                                OFFICE</h1>
                            <img alt="backoffice-koffer label" src={kofferLabel} />
                        </div>
                        <p>Nooit meer een taak vergeten. Altijd alle communicatie met je klant overzichtelijk bij elkaar. Continu inzage in de actuele status van al je dossiers. Kortom, maak jouw reisbedrijf super efficiënt, verhoog de klanttevredenheid en maak je klaar voor de toekomst met onze backoffice module.</p>
                        <ul className="travel-page-container-ul">
                            <li>De TravelSpirit Backoffice</li>
                            <li>Bestellingen</li>
                            <li>CRM</li>
                            <li>Fotobeheer (MediaSpirit)</li>
                            <li>Telefonie</li>
                            <li>Facturen maken</li>
                            <li>Emails</li>
                            <li>Business Intelligence</li>
                            <li>Workflow Management</li>
                            <li>Documenten</li>
                            <li>Travelplans & Producten</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="travel-page-full back-office-section-two">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <h3>
                                “Het handmatig opnieuw
                                invoeren van gegevens is
                                daarmee verleden tijd.”
                            </h3>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>De TravelSpirit Backoffice</h3>
                            <p>Met de backoffice kun je <strong>reisvoorstellen</strong> samenstellen op basis van bouwstenen, voorbeeldreizen, losse elementen zoals auto, hotel en excursies, of combinaties hiervan.</p>
                            <p>Per regel kun je aangeven of iets voor alle reizigers of een enkele reiziger geldt, en de prijs wordt per reiziger berekend. Met extra functies zoals kostprijsberekening en verzekeringen kun je het <strong>hele proces automatiseren.</strong></p>
                            <p>Een Travelplan kan statussen hebben zoals 'offerte', 'geboekt' of 'niet geboekt'. Bij een statuswijziging wordt <strong>automatisch een boekingsbevestiging</strong> of factuur gegenereerd. Het handmatig opnieuw invoeren van gegevens is daarmee verleden tijd.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full crm-office-section" id="crm">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <h3>“Het verzamelen, opslaan en beheren van alle relevante informatie met betrekking tot klanten, leveranciers en andere contacten op één plek.”</h3>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>CRM</h3>
                            <p>Het verzamelen, opslaan en beheren van alle relevante  <strong>informatie</strong> met betrekking tot klanten, leveranciers en andere contacten op <strong>één plek.</strong> Ieder contact heeft zijn eigen contacten cockpit, waar alle gegevens gemakkelijk terug te vinden zijn.</p>
                            <p>Je kunt bijv. de interesses van reizigers bijhouden, zoals luxe, avontuurlijke of sportieve reizen en verschillende relatielabels toepassen, <strong>relatielabels</strong> toepassen, zoals ‘terugkerende klant’ of 'VIP'. Maar ook verschillende soorten relaties vastleggen, zoals ‘medereiziger’, ‘partner’, ‘reisagent’ of ‘thuisblijver’.</p>
                            <p>Door aanvullende informatie zoals de <strong>reisvoorkeuren</strong> van reizigers te bewaren vergroot je de kans dat ze terugkerende klanten worden.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full telephonie-section" id="telephone">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <div>
                                <img alt="backoffice-we cloud IT " src={wecloudIt} />
                            </div>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>Telefonie</h3>
                            <p>Het is mogelijk om TravelSpirit te <strong>koppelen</strong> met de bedrijfs
                                VOIP telefooncentrale. Zo kun je bijv. functies zoals een
                                keuzemenu en <strong>call routing</strong> instellen. En dat niet alleen,
                                voordat de telefoon wordt opgenomen, zie je op het scherm
                                welk contact het betreft en klik je snel door naar de
                                contactencockpit. Daar vind je bijvoorbeeld het laatste emailcontact met de klant, de reis die hij gaat maken, of het
                                een repeater betreft, etc.</p>
                            <p>De meest uitgebreide koppeling is met <strong>WeCloudIT.</strong> Bij deze
                                aanbieder is het zelfs mogelijk om <strong>gesprekken terug te
                                    luisteren</strong> vanaf de gesprekkenlijst, een binnenkomend
                                gesprek automatisch te verbinden aan de juiste verkoper, of
                                direct een klant te bellen zonder het nummer opnieuw te
                                hoeven intoetsen.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full email-section" id="emails">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <h3>“Medewerkers kunnen eenvoudig voortwerken via gedeelde mailboxen, waar ze e-mails van collega’s inzien en oppakken.”</h3>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>Emails</h3>
                            <p>TravelSpirit maakt <strong>digitaal samenwerken</strong> snel en efficiënt. Medewerkers kunnen eenvoudig voortwerken via gedeelde mailboxen, waar ze e-mails van collega’s inzien en oppakken.</p>
                            <p>Handmatig mapjes organiseren is verleden tijd. Met TravelSpirit Mail kun je <strong>taken toewijzen,</strong> e-mails markeren en templates gebruiken.</p>
                            <p><strong>Automatische acties,</strong> zoals het labelen van offertes, zijn eenvoudig in te stellen. In de contacten cockpit stel je snel e-mails op met automatisch ingevulde gegevens en bijlagen. Vanuit een travelplan verstuur je direct offertes, facturen en reisdocumenten, inclusief betaallinks. Verzonden e-mails zijn zichtbaar voor collega's met de juiste rechten, en uitgestelde verzending is mogelijk.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full workflow-section" id="workflow-management">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <h3>“Met de Workflow Managementfunctie kun je al die taken in één keer aanmaken.”</h3>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>Workflow Management</h3>
                            <p>Er moet veel gebeuren, wanneer de klant boekt. Denk aan reisbescheiden en facturen verzenden, bestellingen plaatsen, aanbetalingen checken, tickets printen, etc.</p>
                            <p>Met de <strong>workflow managementfunctie</strong> kun je al die taken in één keer aanmaken, bijvoorbeeld op basis van de vertrekdatum.Stel je voor dat zes weken voor vertrek net op een zondag valt. Je kunt dan bepalen of dit naar de maandag of de vrijdag ervoor verplaatst moet worden.</p>
                            <p>Elke taak bestaat uit een <strong>zichtbaarheidsdatum met deadline</strong>. Taken die nog niet relevant zijn, worden daarom ook nog niet getoond in de takenlijst van de gebruiker, maar wel in de contacten cockpit van de klant!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full producten-section" id="travelplans-product">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <h3>“Alle informatie staat netjes bij elkaar, zodat elke medewerker op ieder moment weet wat de inhoud en status van een boeking is.”</h3>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>Travelplans & producten</h3>
                            <p>Beheer alles rondom de <strong>boeking van je klant op één plek.</strong> Of het nu gaat om Product Informatie Management (PIM), het opvoeren van afspraken met je leverancier of het <strong>factureren</strong> aan de klant: alles kan vanuit het Travelplan</p>
                            <p>Met <strong>Travelplans</strong> kun je o.a. calculeren, PDF offertes en facturen maken, betaallinkjes aanmaken en versturen en bestelling plaatsen bij je leverancier. Alle informatie staat netjes bij elkaar, zodat elke medewerker op ieder moment weet wat de inhoud en status van een boeking is.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full bestellingen-section" id="bestellingen">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <h3>“De bevestiging wordt automatisch in de backoffice verwerkt, zodat je altijd up-todate bent met de status van je bestellingen.”</h3>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>Bestellingen</h3>
                            <p>Plaats <strong>eenvoudig bestellingen</strong> bij leveranciers met informatie uit je reisplan, zoals namen van reizigers, kamertypes, verwachte inkoopprijzen en specifieke leveranciersafspraken, zoals 7=6 deals. Dankzij deze integratie wordt het bestelproces veel <strong>efficiënter</strong> en minder foutgevoelig.</p>
                            <p>Zodra de bestelling is verstuurd, kan de leverancier deze bevestigen. De bevestiging wordt automatisch in de backoffice verwerkt, zodat je <strong>altijd up-to-date</strong> bent met de status van je bestellingen.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full fotobeheer-section" id="foto-beheer-mediaspirit">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <div className="video-wrapper">
                                <video className="responsive-video" autoPlay muted loop width="400" height="500">
                                    <source src={addonVideo} />
                                </video>
                            </div>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>Fotobeheer (MediaSpirit)</h3>
                            <p>De online mediatheek voor uw <strong>afbeeldingenbeheer en - bewerking!</strong></p>
                            <p>Afbeeldingen en video's vormen een steeds belangrijker onderdeel van marketing, zowel online als offline. Het beheren, opslaan en bewerken, kan zonder een goed <strong>fotobeheerprogramma</strong> echter een tijdrovende klus zijn.</p>
                            <p>MediaSpirit is niet alleen handige software om digitale foto’s in te beheren, ordenen en organiseren. Het is ook een heel eenvoudig en intuïtief te bedienen fotobeheerprogramma, waarmee je je afbeeldingen online kunt bewerken. En dankzij de <strong>AI tagging functie</strong> worden je afbeeldingen automatisch van tags voorzien op basis van de inhoud. Dit maakt het zoeken en categoriseren van afbeeldingen een stuk efficiënter.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full facturen-section" id="make-invoice">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <h3>“Dit kan met slechts één druk op de knop.”</h3>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>Facturen maken</h3>
                            <p>De online mediatheek voor uw afbeeldingenbeheer en - bewerking!</p>
                            <p>Maak eenvoudig facturen aan vanuit de backoffice, waardoor het <strong>facturatieproces</strong> soepel en efficiënt verloopt. Heb je een offerte verstuurd en wil je deze snel omzetten naar een factuur? Dit kan met slechts <strong>één druk op de knop</strong>, wat niet alleen tijd bespaart, maar ook dubbele invoer voorkomt en de kans op fouten minimaliseert.</p>
                            <p>De aanbetaling en restantbetaling worden <strong>automatisch berekend,</strong> zodat je altijd zeker bent van de juiste bedragen. Wil je een betaallink meesturen? Geen probleem!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full intelligence-section" id="business-intelligence">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <div>
                                <img alt="backoffice-travelspirit Intelligence" src={travelIntelligence} />
                            </div>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>Business Intelligence</h3>
                            <p>
                                TravelSpirit slaat belangrijke data op, zoals omzet en
                                klanten. De BI-oplossing biedt eenvoudige analyses via
                                Google Data Studio met een interactief dashboard, inclusief
                                analyses zoals:
                            </p>
                            <p>- Conversie per medewerker, bestemming, leverancier of
                                kwartaal;<br />
                                - Marge per afdeling, kantoor of maandCash Flow prognose,
                                inclusief uitgaven aan kantoorkosten zoals salarissen &
                                overhead;<br />
                                - Productiviteit & KPI's</p>
                            <p><strong>Integratie met databronnen</strong> zoals Google Analytics biedt
                                snel inzicht in analyses en helpt bij het identificeren van
                                veelbelovende klanten en deals. TravelSpirit werkt samen
                                met Travel Intelligence voor de configuratie.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full documenten-section" id="documenten">
                <div className="travel-page-container">
                    <div className="all-travel-common-box-main">
                        <div className="all-travel-common-box-left">
                            <h3>“Hierdoor kun je snel gepersonaliseerde communicatie opstellen, met automatisch ingevoegde gegevens.”</h3>
                        </div>
                        <div className="all-travel-common-box-right">
                            <h3>Documenten</h3>
                            <p>Je kunt per contact eenvoudig bestanden zoals Word- en Excel-documenten opslaan, waardoor belangrijke <strong>informatie snel toegankelijk</strong> is en bijlagen bij e-mails snel kunnen worden toegevoegd.</p>
                            <p>Daarnaast kun je Word- of Google Docs-brieven aanvullen met gegevens uit het CRM-systeem. Hierdoor kun je snel gepersonaliseerde communicatie opstellen, met <strong>automatisch ingevoegde gegevens</strong> zoals namen en adressen. Deze functionaliteit bespaart tijd en zorgt ervoor dat je correspondentie altijd nauwkeurig en professioneel blijft.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="travel-page-full kost-section">
                <div className="travel-page-container">
                    <div className="kost-section-content">
                        <h2>Wat kost de TravelSpirit Backoffice?</h2>
                        <p>Voor elke type reisorganisatie bieden wij een passend abonnement inclusief de backoffice.</p>
                        <Link key='backoffice-pricing' to={`/pricing`}>
                            Bekijk abonnementen <span>&gt;</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="travel-page-full benieuwd-section">
                <div className="travel-page-container">
                    <div className="benieuwd-section-content">
                        <h2>Benieuwd wat TravelSpirit voor jou kan betekenen?</h2>
                        <p>Wij gaan graag met je in gesprek om de mogelijkheden te bespreken om je verder te helpen.</p>
                        <div className="benieuwd-btn">
                            <div className="benieuwd-btn-item">
                                <Link key='backoffice-demo' to={`/demo`} className="btn btn-primary demo-implement">
                                    Demo inplannen
                                </Link>
                                <span className="benieuwd-btn-item-text">Online sessie van 30 minuten</span>
                            </div>
                            <div className="benieuwd-btn-item">
                                <Link key='backoffice-verkennend' to={`/demo-afspraak`} className="btn btn-primary verkennend-implement">
                                    Verkennend gesprek
                                </Link>
                                <span className="benieuwd-btn-item-text">Stel online al jouw vragen</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
}